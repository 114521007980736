import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames';
import Layout from '../components/layout'

const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle="home">
      <Container>
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {
          data.allMdx.nodes.map((node) => (
            <Col>
              <Link to={`/${node.frontmatter.type}/${node.slug}`}>
                <GatsbyImage className={classNames('card-img-top')} image={getImage(node.frontmatter.hero_image)} alt={node.frontmatter.hero_image_alt} />
              </Link>
            </Col>
          ))
          }
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {type: {ne: "page"}, draft: {eq: false}}}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          type
          title
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
  }
`

export default IndexPage